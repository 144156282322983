@import 'colors.css';

.rowHeader {
    background: var(--violet);
    color: var(--white);
    border-radius: 5px 0px 0px 5px;
}

.rowHeader .title {
    padding: 20px;
    font-weight: 600;
}

.noRowHeader {
    background: transparent;
    color: white;
}

.rowContent {
    display: flex;
    justify-content: flex-start;
    /* background-color: rgba(255,255,255,0.5); */
    color: black;
    border-radius: 5px;
}

.editableText {
    box-sizing: border-box;
    /* border: 1px solid var(--violet); */
    /* margin: 5px; */
    background-color: white;
    border-radius: 5px;
    /* padding: 5px; */
    height: calc(100% - 10px);
    min-height: 56px;
    min-width: 80px;
    /* width: 100%;
    height: 100%; */
    padding: 10px;
    word-wrap: break-word;
}

.editableText textarea {
    padding: 0px;
}

.readonlyEditableText {
    background-color: var(--light-gray);
    border: 1px solid var(--dark-gray);
    padding: 5px;
}

.entityContainer {
    flex: 1;
    height: 100%;
    position: relative;
}

.entityContainerButtons {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: transparent;
    display: flex;
}

.entityContainerButtonsRelative {
    margin-top: 10px;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
}

.initiativesContainer {
}

.initiativesContainer div {
    margin-bottom: -1px;
}

.pillarHeader {
    border-radius: 5px;
    background-color: var(--live-white);
    color: var(--live-violet);
    margin: 5px;
    padding: 5px;
    text-align: center;
    /* border-radius: 5px; */
    padding-bottom: 10px;
    line-height: 1.4rem;
    font-weight: 600;
}

.NEW {
    background: var(--gray);
    color: #fff;
}

.DRAFT {
    background: var(--violet);
    color: #fff !important;
}

.PUBLIC {
    background: var(--yellow);
}

.FINISHED {
    background: var(--green);
    color: #fff;
}

.onePagerCard {
    margin-bottom: 5px;
    border: 1px solid black;
    cursor: pointer;
}

.onePagerCard .onePagerIcon {
    width: 25px;
    display: flex;
    align-items: center;
    background-color: var(--light-gray);
}

.onePagerCard .onePagerIcon svg {
    color: #bbb;
}

.selectedCard {
    border: 1px solid black;
    margin-bottom: 5px;
    cursor: pointer;
}

.selectedCard .onePagerIcon {
    width: 25px;
    display: flex;
    align-items: center;
    background-color: var(--violet);
}

.selectedCard .onePagerIcon svg {
    color: var(--yellow);
}

.parentPillarNotDefined {
    border: 1px solid var(--dark-red);
    color: var(--dark-red);
    background: #ffeeee;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    font-size: 14px;
    display: flex;
}

.parentOnePagerNotFinished {
    border: 1px solid var(--dark-gray);
    color: var(--dark-gray);
    background: var(--ultra-light-gray);
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    font-size: 14px;
    display: flex;
}

.commentsContainer {
    padding-bottom: 50px;
}

.commentContainer {
    background-color: var(--light-yellow);
    margin-bottom: 10px;
    border-radius: 5px;
    transform: rotate(5);
}

.commentHeader {
    background-color: var(--yellow) !important;
}

.commentContainer div {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--light-yellow);
    box-sizing: border-box;
}

.commentContainer div:nth-child(2) {
    font-style :italic;
}


.alfa .rowHeader {
    background: #D4AFEE;
    border-radius: 5px;
    padding: 5px;
    box-sizing : border-box;
}

.alfa .rowHeader .title {
    padding: 20px;
    color: var(--live-violet);
    font-weight: 600;
}

.alfa .rowContent {
    display: flex;
    justify-content: flex-start;
    color: black;
    border-radius: 5px;
    /* background-color: #8669B0; */
}

.alfa .editableText {
    box-sizing: border-box;
    background-color: #8669B0;
    color: var(--live-white);
    border-radius: 5px;
    height: 100%;
    margin-left: 10px;
    min-height: 50px;
}

.alfa .editableText textarea {
    color: var(--live-white) !important;
}

.allfa .readonlyEditableText {
    background-color: var(--light-gray);
    border: 1px solid var(--dark-gray);
}

.beta .rowHeader {
    background: var(--live-white);
    border-radius: 5px;
    padding: 5px;
    box-sizing : border-box;
}

.beta .rowHeader .title {
    padding: 20px;
    color: var(--live-violet);
    font-weight: 600;
}

.beta .rowContent {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: black;
    border-radius: 5px;
    background-color: var(--live-violet);
}

.beta .entityContainer:nth-child(2), .beta .entityContainer:nth-child(3), .beta .entityContainer:nth-child(4), .beta .entityContainer:nth-child(5) {
    margin-top: 5px;
}

.beta .editableText {
    box-sizing: border-box;
    background-color: transparent;
    color: var(--live-white);
    border-radius: 5px;
    height: 100%;
    min-height: 50px;
    border: 1px solid #D4AFEE;
    margin-left: 10px;
}

.beta .editableText textarea {
    color: var(--live-white) !important;
}

.beta .readonlyEditableText {
    background-color: var(--light-gray);
    border: 1px solid var(--dark-gray);
}

.gamma {
    margin-top: -10px;
}

.gamma .rowHeader {
    background: #D4AFEE;
    border-radius: 5px;
    padding: 5px;
    box-sizing : border-box;
}

.gamma .rowHeader .title {
    padding: 20px;
    color: var(--live-violet);
    font-weight: 600;
}

.gamma .rowContent {
    display: flex;
    justify-content: flex-start;
    color: black;
    border-radius: 0px;
    background-color: #C8ACEA;
    border-left: 20px solid var(--live-violet);
    padding-left: 0px !important;
}

.gamma .rowContent > .entityContainer {
    border-right: 3px solid var(--live-violet);
}

.gamma .editableText {
    box-sizing: border-box;
    background-color: transparent;
    color: var(--live-violet);
    height: calc(100%);
    min-height: 50px;
    border-radius: 0px;
    padding: 5px;
}

.gamma .editableText > div {
    background-color: #E4D6F5;
    border-radius: 5px;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    line-height: 1.3rem;
    padding: 5px;
}

.gamma .editableText textarea {
    color: var(--live-violet) !important;
    background-color: #E4D6F5;
    line-height: 1.3rem;
    width: 100%;
    height: 100%;
}

.gamma .readonlyEditableText {
    background-color: #E4D6F5;
    border-radius: 5px;
    padding: 0px 5px !important;
    margin: 3px;
}

.delta {
    margin-top: -5px;
}

.delta .rowHeader {
    background: var(--live-white);
    border-radius: 5px;
    padding: 5px;
    box-sizing : border-box;
    margin-top: 5px;
}

.delta .rowHeader .title {
    padding: 20px;
    color: var(--live-violet);
    font-weight: 600;
}

.delta .rowContent > .entityContainer {
    border-right: 3px solid var(--live-violet);
}

.delta .rowContent {
    display: flex;
    justify-content: flex-start;
    color: black;
    border-radius: 0px;
    background-color: #C8ACEA;
    border-left: 20px solid var(--live-violet);
    padding-left: 0px !important;
}

.delta .editableText {
    box-sizing: border-box;
    background-color: transparent;
    color: var(--live-violet);
    height: calc(100%);
    min-height: 50px;
    border-radius: 0px;
    padding: 5px;
}

.delta .editableText > div {
    background-color: var(--live-white);
    border-radius: 5px;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    line-height: 1.3rem;
    min-height: 50px;
    padding: 5px;
}

.delta .editableText textarea {
    color: var(--live-violet) !important;
    line-height: 1.3rem;
    width: 100%;
    height: 100%;
}

.delta .readonlyEditableText {
    background-color: #E4D6F5;
    border-radius: 5px;
    padding: 0px 5px !important;
    margin: 3px;
}

.omega {
    margin-top: 10px;
}

.omega .rowHeader {
    background: var(--live-white);
    border-radius: 5px;
    padding: 5px;
    box-sizing : border-box;
    margin-top: 5px;
}

.omega .rowHeader .title {
    padding: 20px;
    color: var(--live-violet);
    font-weight: 600;
}

.omega .rowContent > .entityContainer {
    border-right: 3px solid var(--live-violet);
}

.omega .rowContent {
    display: flex;
    justify-content: flex-start;
    color: black;
    border-radius: 0px;
    background-color: #C8ACEA;
    border-left: 20px solid var(--live-violet);
    padding-left: 0px !important;
}

.omega .editableText {
    box-sizing: border-box;
    background-color: transparent;
    color: var(--live-violet);
    height: calc(100%);
    min-height: 50px;
    border-radius: 0px;
    padding: 5px;
}

.omega .editableText > div {
    background-color: var(--live-white);
    border-radius: 5px;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    line-height: 1.3rem;
    min-height: 50px;
    padding: 5px;
}

.omega .editableText textarea {
    color: var(--live-violet) !important;
    line-height: 1.3rem;
    width: 100%;
    height: 100%;
}

.omega .readonlyEditableText {
    background-color: #E4D6F5;
    border-radius: 5px;
    padding: 0px 5px !important;
    margin: 3px;
}
